@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

::-webkit-scrollbar-thumb:hover {
  background-color: black;
}

.gradient-background {
  background-image: url("../src/assets/images/gradient.png");
}



.line {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center; 

  &:before,
  &:after {
    background-color: #aaa;
    content: '\a0';
    flex-grow: 1;
    height: 1px;
    position: relative;
    top: 0.6em;
  }

  &:before {
    margin-right: 0.35em;
  }

  &:after {
    margin-left: 0.35em;
  }
}
.card-gradient-border {
  position: relative;
}

.card-gradient-border::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 5px; /* Width of the left border */
  background: linear-gradient(180deg, #0013b9, #7c0043);
  border-top-left-radius: 0.5rem; /* If you need rounded corners */
  border-bottom-left-radius: 0.5rem; /* If you need rounded corners */
}

.ant-dropdown-menu-item{
padding: 7px 36px !important;
}